import "./sub-page-hero.scss";

import { node, shape, string } from "prop-types";

import MediaBlock from "../MediaBlock/MediaBlock.jsx";
import YouTube from "react-youtube";
import cx from "classnames";
import styled from "styled-components";
import tagFields from "../../common/extractTag.js";
import useAOS from "../../common/useAOS.js";

const propTypes = {
  title: string.isRequired,
  subtitle: node,
  foregroundImage: node.isRequired,
  foregroundVideoId: string,
  backgroundImage: node,
  tag: shape(tagFields),
};

//handle the background scrim color propvided by contenful tag
const SubPageHeroImgWrapper = styled.div`
  &:before {
    background: ${(props) =>
      props.color ? props.color.colorValue : "#000000"};
  }
`;

const SubPageHero = (props) => {
  const {
    foregroundImage,
    foregroundVideoId,
    backgroundImage,
    title,
    subtitle,
    tag,
  } = props;

  useAOS();

  return (
    <section className="sub-page-hero container container--bleed container--has-background">
      <SubPageHeroImgWrapper
        className="sub-page-hero__background-img"
        color={tag && tag.color ? tag.color : null}
      >
        {backgroundImage}
      </SubPageHeroImgWrapper>
      <div className="container container--100 sub-page-hero__content-wrapper">
        <h1
          data-aos="fade-down"
          data-aos-offset="0"
          className="sub-page-hero__title"
        >
          <strong>{title}</strong>
        </h1>
        <div
          data-aos="fade-down"
          data-aos-offset="0"
          className="sub-page-hero__subtitle"
        >
          {subtitle}
        </div>
        <MediaBlock
          offset={0.9}
          className={cx({ "media-block--video": foregroundVideoId })}
        >
          {foregroundVideoId && (
            <YouTube className="you-tube-video" videoId={foregroundVideoId} />
          )}
          {!foregroundVideoId && (
            <div className="sub-page-hero__foreground-img">
              {foregroundImage}
            </div>
          )}
        </MediaBlock>
      </div>
    </section>
  );
};

SubPageHero.propTypes = propTypes;
export default SubPageHero;
