import "./about-us-slider-block.scss";

import { arrayOf, node, object } from "prop-types";

import AboutUsSlider from "./AboutUsSlider.jsx";
import Container100 from "../Container/Container100";
import InfoBlockSlide from "./InfoBlockSlide.jsx";
import PlusSigns from "../PlusSigns/PlusSigns.jsx";
import useAOS from "../../common/useAOS.js";

const propTypes = {
  title: node.isRequired,
  subtitle: node,
  slides: arrayOf(object).isRequired, // ImageBlock will type check
};

const AboutUsSliderBlock = (props) => {
  const { title, subtitle, slides } = props;

  useAOS();

  return (
    <div className="about-us-slider-block">
      <Container100>
        <PlusSigns style={{ zIndex: "0" }} />
        <div className="about-us-slider-block__header">
          <h2 data-aos="fade-down" className="about-us-slider-block__title">
            <strong>{title}</strong>
          </h2>

          {subtitle && (
            <div
              data-aos="fade-down"
              className="about-us-slider-block__subtitle"
            >
              {subtitle}
            </div>
          )}
        </div>
      </Container100>
      <AboutUsSlider>
        {slides.map((slide, i) => (
          <InfoBlockSlide key={i} {...slide} />
        ))}
      </AboutUsSlider>
    </div>
  );
};

AboutUsSliderBlock.propTypes = propTypes;
export default AboutUsSliderBlock;
