import "./info-block-slide.scss";

import { node } from "prop-types";

const propTypes = {
  title: node.isRequired,
  text: node,
};

const InfoBlockSlide = (props) => {
  const { title, text } = props;

  return (
    <section className="info-block-slide">
      <div className="info-block-slide__content">
        <h2 className="info-block-slide__title">{title}</h2>
        {text && <div className="info-block-slide__text">{text}</div>}
      </div>
    </section>
  );
};

InfoBlockSlide.propTypes = propTypes;
export default InfoBlockSlide;
