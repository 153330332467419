import AboutUsPage from "../components/AboutUsPage/AboutUsPage.jsx";

const propTypes = {};

const AboutUs = (props) => {
  return <AboutUsPage />;
};

AboutUs.propTypes = propTypes;
export default AboutUs;
