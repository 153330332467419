import * as R from "ramda";

import extractInfoBlock from "./extractInfoBlock.js";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default R.compose(
  R.applySpec({
    title: R.prop("slideShowTitle"),
    subtitle: R.compose(
      R.unless(R.isNil, renderRichText),
      R.prop("slideShowSubtitle"),
    ),
    slides: R.compose(R.map(extractInfoBlock), R.prop("slides")),
  }),
  R.defaultTo({}),
);
