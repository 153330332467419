import "./two-up-text-banner.scss";

import { node, shape } from "prop-types";

import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import cx from "classnames";
import { linkFields } from "../../common/extractLink.js";
import { navigate } from "gatsby";
import useAOS from "../../common/useAOS.js";

const propTypes = {
  leftTitle: node,
  leftText: node,
  leftLink: shape(linkFields),
  rightTitle: node,
  rightText: node,
  rightLink: shape(linkFields),
  backgroundImage: node,
};

const renderCard = (title, text, link) => {
  return (
    <div data-aos="fade-down" className="two-up-text-banner__inner">
      {title && (
        <h2>
          <strong>{title}</strong>
        </h2>
      )}
      {text && <div className="two-up-text-banner__text">{text}</div>}
      {link && (
        <div className="two-up-text-banner__link">
          <ContentfulLink className="btn btn--big btn--ghost" {...link} />
        </div>
      )}
    </div>
  );
};

const TwoUpTextBanner = (props) => {
  const {
    leftTitle,
    leftText,
    leftLink,
    rightTitle,
    rightText,
    rightLink,
    backgroundImage,
  } = props;

  useAOS();

  return (
    <section className="two-up-text-banner">
      <div className="two-up-text-banner__background">{backgroundImage}</div>
      <div className="two-up-text-banner__wrapper">
        <div
          role="presentation"
          onClick={() => leftLink && navigate(leftLink.to)}
          className={cx("two-up-text-banner__left", {
            "two-up-text-banner__left--has-link": leftLink,
          })}
        >
          {renderCard(leftTitle, leftText, leftLink)}
        </div>

        <div
          role="presentation"
          onClick={() => rightLink && navigate(rightLink.to)}
          className={cx("two-up-text-banner__right", {
            "two-up-text-banner__right--has-link": rightLink,
          })}
        >
          {renderCard(rightTitle, rightText, rightLink)}
        </div>
      </div>
    </section>
  );
};

TwoUpTextBanner.propTypes = propTypes;
export default TwoUpTextBanner;
