import "./about-us-slider.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  A11y,
  Mousewheel,
  Navigation,
  Scrollbar,
} from "swiper/core";
import { useEffect, useState } from "react";

import { node } from "prop-types";
import useAOS from "../../common/useAOS.js";
import useMatchMedia from "../../common/useMatchMedia.js";

const propTypes = { children: node.isRequired };

SwiperCore.use([Navigation, A11y, Scrollbar, Mousewheel]);

function padNum(num) {
  const string = num.toString();
  return num.toString().length === 1 ? "0" + string : string;
}

const SlideShow = (props) => {
  const { children } = props;

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useAOS();

  const isDesktop = useMatchMedia("(min-width: 910px)"); // matches $bp-large-min in _breakpoints.scss

  return hasMounted ? (
    <section data-aos="fade-down" className="about-us-slider">
      <Swiper
        a11y
        scrollbar={{
          draggable: true,
          dragSize: 100,
          el: ".about-us-slider__scrollbar",
        }}
        slidesPerView={isDesktop ? 3 : 2}
        spaceBetween={isDesktop ? 100 : 30}
        freeMode={true}
        centeredSlides={true}
        mousewheel={true}
      >
        {Array.isArray(children) ? (
          children.map((child, i, array) => (
            <SwiperSlide key={i}>
              <div className="about-us-slider__slide">
                <div className="about-us-slider__index">
                  <span>
                    <strong>{padNum(i + 1)}</strong>
                  </span>
                  <span className="about-us-slider__index-seperator"> of </span>
                  <span>
                    <strong>{padNum(array.length)}</strong>
                  </span>
                </div>
                {child}
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>{children}</SwiperSlide>
        )}
      </Swiper>
      <div className="about-us-slider__scrollbar" />
    </section>
  ) : null;
};

SlideShow.propTypes = propTypes;
export default SlideShow;
