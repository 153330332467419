import * as R from "ramda";

import extractImage from "./extractImage.jsx";
import extractLink from "./extractLink.js";
import extractRichText from "./extractRichText.jsx";

const extractTwoUpTextBanner = R.compose(
  R.applySpec({
    leftTitle: R.prop("leftTitle"),
    leftText: R.compose(extractRichText, R.prop("leftText")),
    leftLink: R.compose(extractLink, R.prop("leftLink")),
    rightTitle: R.prop("rightTitle"),
    rightText: R.compose(extractRichText, R.prop("rightText")),
    rightLink: R.compose(extractLink, R.prop("rightLink")),
    backgroundImage: R.compose(extractImage, R.prop("backgroundImage")),
  }),
  R.defaultTo({}),
);

export default extractTwoUpTextBanner;
