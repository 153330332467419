import * as R from "ramda";

import extractImage from "./extractImage.jsx";

export default R.compose(
  R.applySpec({
    title: R.prop("subPageHeroTitle"),
    subtitle: R.prop("subPageHeroSubtitle"),
    foregroundImage: R.ifElse(
      R.compose((data) => (data ? true : false), R.prop("foregroundImage")),
      R.compose(extractImage, R.prop("foregroundImage")),
      () => null,
    ),
    foregroundVideoId: R.ifElse(
      R.compose(
        (data) => (data ? true : false),
        R.path(["foregroundVideo", "videoId"]),
      ),
      R.path(["foregroundVideo", "videoId"]),
      () => null,
    ),
    backgroundImage: R.unless(
      R.compose(R.isNil, R.prop("backgroundImage")),
      R.compose(extractImage, R.prop("backgroundImage")),
    ),
  }),
  R.defaultTo({}),
);
