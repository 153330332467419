import "./bread-crumb.scss";

import * as R from "ramda";

import Container100 from "../Container/Container100.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import Dropdown from "../Dropdown/Dropdown.jsx";
import { string } from "prop-types";
import { useContentfulSiteMap } from "../../common/contentfulSiteMap.jsx";
import useMatchMedia from "../../common/useMatchMedia.js";

const propTypes = { pageId: string.isRequired };

const BreadCrumb = (props) => {
  const siteMap = useContentfulSiteMap();

  const { pageId, override } = props;

  const breadCrumbs = R.compose(R.path([pageId, "breadCrumbs"]))(siteMap);
  const isDesktop = useMatchMedia("(min-width: 910px)"); // matches $bp-large-min in _breakpoints.scss

  const extractBreadCrumb = (id) =>
    R.compose(
      R.tap(console.log),
      R.applySpec({ title: R.prop("title"), path: R.prop("path") }),
      R.prop(id)
    )(siteMap);

  const mappedBreadCrumbs = () => {
    if (override) return override;
    return breadCrumbs
      ? breadCrumbs
          .filter((breadCrumb) => breadCrumb !== pageId)
          .map((breadCrumb) =>
            breadCrumb === ""
              ? { title: "Home", path: "/" }
              : extractBreadCrumb(breadCrumb)
          )
      : [];
  };

  const renderBreadCrumbs = (arr) => {
    const breadCrumbs =
      isDesktop || arr.length <= 3
        ? arr
        : [arr[0], arr.slice(1, arr.length - 1), arr[arr.length - 1]]; // first, last, and everything in between

    return breadCrumbs.map((breadCrumb, i, array) => {
      const isLast = i === array.length - 1;

      // Render dropdown for middle elements on mobile
      if (Array.isArray(breadCrumb)) {
        return (
          <li key={i} className="bread-crumb__crumb">
            <Dropdown className="bread-crumb__dropdown" button={"..."}>
              <ul>
                {breadCrumb.map((child) => (
                  <ContentfulLink
                    key={child.title}
                    to={child.path}
                    type="internal"
                  >
                    {child.title}
                  </ContentfulLink>
                ))}
              </ul>
            </Dropdown>
            {!isLast && chevronSvg}
          </li>
        );
      } else
        return (
          <li key={breadCrumb.title} className="bread-crumb__crumb">
            <ContentfulLink to={breadCrumb.path} type="internal">
              {breadCrumb.title}
            </ContentfulLink>
            {!isLast && chevronSvg}
          </li>
        );
    });
  };

  return (
    <div className="bread-crumb">
      <Container100>
        <ul className="bread-crumb__list">
          {renderBreadCrumbs(mappedBreadCrumbs())}
        </ul>
      </Container100>
    </div>
  );
};

BreadCrumb.propTypes = propTypes;
export default BreadCrumb;

const chevronSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-right"
  >
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);
