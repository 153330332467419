import "./about-us-page.scss";

import * as R from "ramda";

import { graphql, useStaticQuery } from "gatsby";

import AboutUsSliderBlock from "../AboutUsSlider/AboutUsSliderBlock.jsx";
import BreadCrumb from "../BreadCrumb/BreadCrumb.jsx";
import Container100 from "../Container/Container100.jsx";
import ContainerBleed from "../Container/ContainerBleed.jsx";
import OutlineTextPromo from "../OutlineTextPromo/OutlineTextPromo.jsx";
import Page from "../Page/Page.jsx";
import SignUp from "../SignUp/SignUp.jsx";
import SubPageHero from "../SubPageHero/SubPageHero.jsx";
import TwoUpTextBanner from "../TwoUpTextBanner/TwoUpTextBanner.jsx";
import extractAboutUsSlider from "../../common/extractAboutUsSlider.js";
import extractRichText from "../../common/extractRichText.jsx";
import extractSubPageHero from "../../common/extractSubPageHero.js";
import extractTwoUpTextBanner from "../../common/extractTwoUpTextBanner.js";
import useAOS from "../../common/useAOS.js";

const propTypes = {};

const AboutUsPage = (props) => {
  const contentfulData = useStaticQuery(query);

  const data = R.compose(
    R.evolve({
      id: R.identity,
      aboutUsHero: extractSubPageHero,
      richText1: extractRichText,
      richText2: extractRichText,
      richText3: extractRichText,
      richText4: extractRichText,
      aboutUsSlider: extractAboutUsSlider,
      aboutUsTwoUpTextBanner: extractTwoUpTextBanner,
    }),
    R.defaultTo({}),
    R.prop("contentfulAboutPage"),
  )(contentfulData);

  const {
    id,
    aboutUsHero,
    richText1,
    richText2,
    richText3,
    richText4,
    aboutUsSlider,
    aboutUsTwoUpTextBanner,
  } = data;

  useAOS();

  return (
    <Page
      pageTitle="Our Story"
      pageDescription={aboutUsHero && aboutUsHero?.subtitle}
      className="about-us-page"
    >
      <BreadCrumb pageId={id} />
      {aboutUsHero && <SubPageHero {...aboutUsHero} />}
      {richText1 && (
        <Container100 data-aos="fade-down">{richText1}</Container100>
      )}
      {AboutUsSliderBlock && <AboutUsSliderBlock {...aboutUsSlider} />}
      {richText2 && (
        <ContainerBleed className="container--has-background about-us-page__rich-text-2-container">
          <div data-aos="fade-down" className="about-us-page__rich-text-2">
            {richText2}
          </div>
        </ContainerBleed>
      )}
      {richText3 && (
        <Container100 data-aos="fade-down">{richText3}</Container100>
      )}
      <OutlineTextPromo
        textLine1="It takes all of us"
        textLine2="It takes all of us"
        dark={true}
      />
      {richText4 && (
        <Container100
          data-aos="fade-down"
          className="about-us-page__rich-text-4-container"
        >
          {richText4}
        </Container100>
      )}
      {/*{AboutUsSliderBlock && <AboutUsSliderBlock {...aboutUsSlider} />}*/}
      <ContainerBleed>
        <SignUp
          title="Join Our Movement"
          subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
        />
      </ContainerBleed>
      {aboutUsTwoUpTextBanner && (
        <ContainerBleed>
          <TwoUpTextBanner {...aboutUsTwoUpTextBanner} />
        </ContainerBleed>
      )}
    </Page>
  );
};

AboutUsPage.propTypes = propTypes;
export default AboutUsPage;

const query = graphql`
  {
    contentfulAboutPage(id: { eq: "080af153-9c68-5d4b-8d0b-30a2009b069d" }) {
      id
      aboutUsHero {
        ...SubPageHero
      }
      richText1 {
        raw
        references {
          ...RichTextReferences
        }
      }
      richText2 {
        raw
        references {
          ...RichTextReferences
        }
      }
      richText3 {
        raw
        references {
          ...RichTextReferences
        }
      }
      richText4 {
        raw
        references {
          ...RichTextReferences
        }
      }
      aboutUsSlider {
        ...AboutUsSlider
      }
      aboutUsTwoUpTextBanner {
        ...TwoUpTextBanner
      }
    }
  }
`;
